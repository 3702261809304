import React from 'react'
import {PageProps} from 'gatsby'
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import { Link } from "gatsby"
import Button from '@components/Basic/Button'
import {facebookUrl} from '@src/config'

const Clients = (props: PageProps) => {
   
    return (
        <div className="contacts section" id="contacts">
            <div className="container">
                <h1 className="title">Contattaci</h1>
                <div className="flex justify-center">
                    <p>Scrivici su</p>
                    
                    <a href={facebookUrl} target="_blank" className="btn facebook">
                        facebook
                    </a>
                </div>
            </div> 
        </div>
    )
}


export default Clients