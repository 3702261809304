import React from "react"
// import { Link } from "gatsby"

import Layout from "@components/layout"
// import Image from "@components/image"
import SEO from "@components/seo"
import Hero from "@components/Hero"
// import Services from "@components/Services"
import Clients from "@components/Clients"
// import CaseStudies from "@components/CaseStudies"
import Contacts from "@components/Contacts"


const IndexPage = () => ( 
  <Layout>
    <SEO title="Home" />
    <Hero />
    {/* <Services /> */}
    <Contacts />
    <Clients />
    {/* <CaseStudies /> */}
  </Layout>
)


export default IndexPage
