import React from 'react'
import {PageProps, Link} from 'gatsby'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Clients = (props: PageProps) => {
    const data = useStaticQuery(graphql`
        query clients {    
            allClientsJson {
                nodes {
                    title
                    text
                    images {
                        alt
                        link
                        src {
                            childImageSharp {
                                fluid(maxWidth: 318, maxHeight: 150, quality: 100, pngQuality: 90, jpegQuality: 90) {
                                    ...GatsbyImageSharpFluid
                                }
                                id
                            }
                        }
                    }
                }
            }
    }`)
    const images = data.allClientsJson.nodes[0].images;
    const text = data.allClientsJson.nodes[0].text;
    const title = data.allClientsJson.nodes[0].title;

    return (
        <div className="clients section" id="clients">
            <div className="container">
                <h1 className="title">{title}</h1>
                <div className="subtitle">{text}</div>
                { images.length > 0 && 
                    <div className="flex flex-wrap client-grid">
                        {images.map(img => (
                            <a href={img.link} target="_blank" key={img.src.childImageSharp.id} className="client-image">
                                <Img  fluid={img.src.childImageSharp.fluid} alt={img.alt} />
                            </a>
                        ))}
                    </div> 
                }
            </div> 
        </div>
    )
}


export default Clients